import {GETALLCOMPANYLIST} from "@/redux/constant";
const companyList: any[]=[]
 function  companyReducer(preState:any,action:any){
    const {type,data} = action
    switch (type){
        case GETALLCOMPANYLIST:
            return {
                companyList:data
            }
        default:
            return {
                ...companyList
            }
    }
}
export default companyReducer
