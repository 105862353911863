import {GETDATASTRUCTUREINFO} from "@/redux/constant";
import {myDataStructureInfoType} from "@/redux/types/myDataStructureInfoType";

const myDataStructureInfo:myDataStructureInfoType={}
function dataStructureReducer(preState:any,action:any){
    const {type,data} = action
    switch (type){
        case GETDATASTRUCTUREINFO:
            return{
                myDataStructureInfo:data
            }
        default:
            return{
                ...myDataStructureInfo
            }
    }
}
export default dataStructureReducer
