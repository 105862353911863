import {Button, message, Radio, RadioChangeEvent} from "antd";
import React, {useState} from "react";
import {bzlWebhook, sslWebhook} from "@/settings/web";
import TextArea from "antd/es/input/TextArea";
import {reqSendRobotMessage} from "@/api/b/robot";
import styles from './index.module.css'
const RobotMangement=()=>{
    const [webhook, setWebhook] = useState(sslWebhook);
const [messageWord, setMessageWord] = useState("");
    const onChange = (e: RadioChangeEvent) => {
        // console.log('radio checked', e.target.value);
        setWebhook(e.target.value);
        setMessageWord("")
    };

    const onChangeMessage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMessageWord(e.target.value);
    };
    const sendMessage=()=>{
        console.log("此时的value是",webhook,messageWord)
        let ploay= {
            webhook,
            message:messageWord,
            messageType:'text'
        }
        reqSendRobotMessage(ploay).then(res=>{
            if (res.status){
                message.success("发送成功~")
                setMessageWord("")
            }else {
                message.error("发送失败~")
            }
        })
    }
    return(
        <div className={styles['main-box']} >
            <Radio.Group onChange={onChange} value={webhook}>
                <Radio value={sslWebhook}>花好月圆群</Radio>
                <Radio value={bzlWebhook}>优秀青年交流群</Radio>
            </Radio.Group>
            <TextArea
                showCount
                maxLength={1000}
                value={messageWord}
                onChange={onChangeMessage}
                placeholder="请输入要发送的信息"
                style={{ height: 120, resize: 'none',margin:'20px 0' }}
            />
            <Button onClick={sendMessage}>立即发送</Button>
        </div>
    )
}
export default RobotMangement