import React, { Suspense, useEffect } from 'react';
import './App.module.css';
import LayoutMain from "./pages";
import './styles/antdCss.css';
import './index.css';
import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider } from "antd";
import LoadingPage from "@/components/loadingPage";
import { stopF12, stopKeyF12 } from "@/utils/stop";
import { BrowserRouter as Router } from 'react-router-dom';
import '@/styles/global.less'
import {themeColor} from "@/settings/theme";
import FullScreenLoading from "@/components/FullScreenLoading";
function App() {
    // console.log("app执行了")
    const currentUrl = window.location.href;

    // if (currentUrl.includes("www.lblssl.cn")) {
    //     eval("debugger;")
    //     setInterval(function () {
    //         eval('(function() {var a = new Date(); debugger; return new Date() - a > 100;}())');
    //     }, 1);
    //     stopKeyF12()
    // }

    return (
        <div className="App" style={{width:'100%', height: '100%' ,background:'#f7f9fe'}}>
            <ConfigProvider locale={zhCN} theme={{
                token: {
                    // Seed Token，影响范围大
                    colorPrimary: themeColor,
                    borderRadius: 5,
                    fontFamily: '"LXGW WenKai GB", sans-serif', // 修复后的代码
                },
            }}>
                <Router>
                    <Suspense fallback={<FullScreenLoading></FullScreenLoading>}>
                        <LayoutMain />
                        {/*<FullScreenLoading></FullScreenLoading>*/}
                    </Suspense>
                </Router>
            </ConfigProvider>
        </div>
    );
}

export default App;
{/*<LoadingPage></LoadingPage>*/}
{/*<FullScreenLoading></FullScreenLoading>*/}