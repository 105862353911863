import React from 'react';
import styles from './index.module.css';
import preloader from './img/preloader.gif'
import {personalPic, sslImg} from "@/settings/web";
import imgStyles from './img.module.css'
import {computerLoading} from "@/settings/theme";
const FullScreenLoading: React.FC = () => {
    return (

        <div className={styles.mainBox}>
            {/*loading旋转*/}
            {/*<div className={styles['container']}>*/}
            {/*    <div className={styles['dot']}></div>*/}
            {/*    <div className={styles['dot']}></div>*/}
            {/*    <div className={styles['dot']}></div>*/}
            {/*    <div className={styles['dot']}></div>*/}
            {/*    <div className={styles['dot']}></div>*/}
            {/*    <div className={styles['dot']}></div>*/}
            {/*    <div className={styles['dot']}></div>*/}
            {/*    <div className={styles['dot']}></div>*/}
            {/*</div>*/}
            {/*gif*/}
            {/*<img  src={preloader} alt=""/>*/}
            <img  className={styles['imgStyle']} src={computerLoading} alt=""/>

            {/*    头像loading*/}
            {/*<div className={imgStyles['new-style']}>*/}
            {/*    <div style={{background: `url(${sslImg})`, backdropFilter: 'blur(0px)'}}*/}
            {/*         className={imgStyles['avatar-style']}>*/}
            {/*        <div className={imgStyles['avatar-bg']}>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*    /!*<div className={styles['loader']}></div>*!/*/}
            {/*</div>*/}
        </div>
    );
};

export default FullScreenLoading;

