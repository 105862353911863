import {
    SERBOARDCOMMENTID,
    SETBOARDCOMMENTDATA, SETCHILDLOADINGID,
    SETCOMMENTID,
    SETCOMMENTlISTREDUX, SETISMOBILE,
    SETLOGINSTATE,
    SETSHOWLOGIN,
    SETUSERINFO
} from "@/redux/constant";

//设置登录状态
export const setLoginStateAction=(data:any)=>{
    return {type:SETLOGINSTATE,data}
}
//存储用户登录信息
export const setUserInfoAction=(data:any)=>{
    return {type:SETUSERINFO,data}
}
//存储当前博客评论id
export const setCommentIdAction=(data:any)=>{
    return {type:SETCOMMENTID,data}
}
//设置登录框是否展示
export const setShowLoginAction=(data:any)=>{
    return {type:SETSHOWLOGIN,data}
}
//存储博客全部评论信息
export const setCommentInfoAction=(data:any)=>{
    return {type:SETCOMMENTlISTREDUX,data}
}
//存储留言板全部评论信息
export const setBoardCommentInfoAction=(data:any)=>{
    return {type:SETBOARDCOMMENTDATA,data}
}
//存储当前留言板评论id
export const setBoardCommentIdAction=(data:any)=>{
    return {type:SERBOARDCOMMENTID,data}
}
//存储当前留言板loadingshowid
export const setChildLoadingIdAction=(data:any)=>{
    return {type:SETCHILDLOADINGID,data}
}

//存储当前是否是移动端的状态
export const setIsMobileAction=(data:any)=>{
    return {type:SETISMOBILE,data}
}
