import {Button, Form, FormProps, Input, message, Radio, RadioChangeEvent} from 'antd'
import styles from './index.module.css'
import {useState} from "react";
import {reqAddWord} from "@/api/b/word";
import TextArea from "antd/es/input/TextArea";
const WordMangement=()=>{
    const [wordType, setWordType] = useState<string>("0")
    const [wordValue, setWordValue] = useState<string>("")
    const [author, setAuthor] = useState<string>("")
    const onChange = (e: RadioChangeEvent) => {
        console.log('radio checked', e.target.value);
        setWordType(e.target.value);
    };

    type FieldType = {
        wordValue:string;
        author?:string;

    };

    const onFinish: FormProps<FieldType>['onFinish'] = (values:any) => {
        console.log('Success:', values);
        reqAddWord({
            value:wordValue,
            author:author,
            type:wordType
        }).then(res=>{
            if (res.status){
                message.success("添加金句成功~")
            }else{
                message.error("添加金句失败,请重试~")
            }
        })
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo:any) => {
        console.log('Failed:', errorInfo);
    };

    const hanleWordValueChange=(e:any)=>{
        setWordValue(e.target.value);
    }
    const hanleAuthorChange=(e:any)=>{
        setAuthor(e.target.value);
    }

    return (
        <div className={styles['main-box']}>

            <div className={styles['header']}>
                <Radio.Group onChange={onChange} value={wordType}>
                    <Radio value={"0"}>鸡汤</Radio>
                    <Radio value={"1"}>笑话</Radio>
                    <Radio value={"2"}>早安</Radio>
                    <Radio value={"3"}>晚安</Radio>
                    <Radio value={"4"}>诗词</Radio>
                    <Radio value={"5"}>笔芯</Radio>
                </Radio.Group>


                <Form

                    layout="inline"
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{width:800}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item<FieldType>
                        label="金句"
                        name="wordValue"

                    >
                        <TextArea showCount   style={{width:250,marginRight:50}}    value={wordValue} maxLength={250} onChange={hanleWordValueChange} placeholder="请输入金句" />
                        {/*<Input value={wordValue} onChange={hanleWordValueChange}/>*/}
                    </Form.Item>

                    <Form.Item<FieldType>
                        label="作者"
                        name="author"
                    >
                        <Input value={author} onChange={hanleAuthorChange}/>
                    </Form.Item>


                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            添加
                        </Button>
                    </Form.Item>
                </Form>

            </div>
        </div>
    )
}
export default WordMangement