import { useEffect } from 'react';
import {useLocation} from "react-router-dom";


const ScrollToTop = () => {
    const { pathname } = useLocation();
    console.log("@@@@@路由置换触发了",pathname)
    useEffect(() => {
        // console.log("指定执行了")
        window.scrollTo(0, 0); // 在页面加载完成时滚动到顶部
    }, []); // 仅在组件挂载时执行一次

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]); // 仅在路由变化时执行

    return null;
};

export default ScrollToTop;
