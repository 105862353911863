import {PERMISSIONROUTEMENU} from "@/redux/constant";

const permissionRoute:any={
    routes:[]
}
function  permissionReducer(preState: any=permissionRoute, action: any){
const {type,data} = action
    // console.log("从接口获取的值是",data)
    switch(type){
        case PERMISSIONROUTEMENU:
            console.log("PERMISSIONROUTEMENU触发了")
            return {
                ...preState,
                routes:data
            }
        default:
            // console.log("默认触发了")
            return {
                ...preState
            }
    }
}

export default permissionReducer
