export  const   GETDATASTRUCTUREINFO='getDatastureInfo'
export const    GETALLCOMPANYLIST   ='getAllCompanyList'
export const PERMISSIONROUTEMENU='permissionRouteMenu'
//设置登录状态
export const SETLOGINSTATE='setLoginState'
//保存用户信息
export const SETUSERINFO = 'setUserInfo'
//存储博客评论id
export const SETCOMMENTID="setCommentId"
//设置登录框是否展示
export const SETSHOWLOGIN   ="setShowLogin"
//存储博客全部评论
export const SETCOMMENTlISTREDUX="setCommentListRedux"
//存储留言板全部评论
export const SETBOARDCOMMENTDATA="setBoardCommentData"
//存储留言板评论id
export const SERBOARDCOMMENTID="setBoardCommentId"
//修改留言板展示loading评论id
export const SETCHILDLOADINGID='setChildLoadingId'

//判断当前是否为移动端
export const SETISMOBILE = "setIsMobile"
