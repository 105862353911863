import {
    SERBOARDCOMMENTID,
    SETBOARDCOMMENTDATA, SETCHILDLOADINGID,
    SETCOMMENTID,
    SETCOMMENTlISTREDUX, SETISMOBILE,
    SETLOGINSTATE,
    SETSHOWLOGIN,
    SETUSERINFO
} from "@/redux/constant";

//登录状态
const loginState = false
//用户个人信息
const userInfo = {}
//当前评论是否显示,存储当前评论的id
const commentId = ""
//登录框是否打开
const isShowLogin = false
//存储全部评论相关信息
const commentObjData = {
    commentListRedux: [],
}
//存储留言板评论相关信息
const boardCommentData = {
    boardListRedux: [],
}
//存储留言板当前编辑评论的唯一id
const boardCommentId = ""
//展示loading评论二级页
const isShowChildLoadingId = ''

//判断当前是否是移动端
const isMobile = false

function globalStateReducer(state: any, action: any) {
    const {type, data} = action
    switch (type) {
        case SETLOGINSTATE:
            return {
                ...state,
                loginState: data
            }
        case SETISMOBILE:
            return {
                ...state,
                isMobile: data
            }
        case SETUSERINFO:
            return {
                ...state,
                userInfo: {
                    ...data
                }
            }
        case SETCOMMENTID:
            return {
                ...state,
                commentId: data
            }
        case SETSHOWLOGIN:
            return {
                ...state,
                isShowLogin: data
            }
        case SETCOMMENTlISTREDUX:
            return {
                ...state,
                commentObjData: {
                    ...state.commentObjData,
                    commentListRedux: [...data]
                }
            }
        case SETCHILDLOADINGID:
            return {
                ...state,
                isShowChildLoadingId: data
            }
        case SETBOARDCOMMENTDATA:
            return {
                ...state,
                boardCommentData: {
                    ...state.boardCommentData,
                    boardListRedux: [...data]
                }
            }
        case SERBOARDCOMMENTID:
            return {
                ...state,
                boardCommentId: data
            }
        default:
            return {
                ...state
            }

    }
}

export default globalStateReducer
