import dataStructureReducer from "@/redux/reducers/dataStructureReducer";
import companyReducer from "@/redux/reducers/companyReducer";
import permissionReducer from "@/redux/reducers/permissionReducer";
import globalStateReducer from "@/redux/reducers/globalStateReducer";
export const reducer ={
    dataStructureReducer,
    companyReducer,
    permissionReducer,
    globalStateReducer
}
